import React from 'react';
import Layout from '../Layout/layout';
import { Helmet } from 'react-helmet';
import FeaturedImage from "../../components/FeaturedImage";
import Author from "../../components/Author";
import { graphql, Link } from 'gatsby';
import Share from '../Share';

export default function BlogPost({ data }) {
  const post = data.allWpPost.nodes[0];
  const site = data.site.siteMetadata;

  //console.log('post', post);
  const ogimage = post.seo.opengraphImage?.localFile?.childImageSharp.fluid.src;
  const twitterimage = post.seo.twitterImage?.localFile?.childImageSharp.fluid.src;
  const _image = site.baseUrl + ogimage;
  const _twitterimage = site.baseUrl + twitterimage;
  
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Layout>
      <Helmet>
        <title>{post.seo.title} | {site.title}</title>
        <meta name="description" content={post.seo.opengraphDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title} />
        <meta property="og:url" content={url+ post.slug} />
        <meta property="og:image" content={_image} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={post.title} />
        {post.seo.twitterImage !== null ? <meta property="twitter:image" content={_twitterimage} /> : null}
        

        <link rel="canonical" href={url} />
      </Helmet>
      <div className="crumb p-4 pb-0 pl-xl-0 pt-xl-0">
        <Link to="/">Startsida</Link> / {post.title}
      </div>
      <div className="article pl-4 pr-4 pb-0 pt-0 p-xl-0">
        {post.featuredImage != null ? <FeaturedImage image={post.featuredImage.node} /> : ''}
        <h1>{post.title}</h1>
        <div className="meta-data">
          <span className="post-date">{post.date} | <Author key={post.author.node.name} node={post.author.node} /></span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
        <div className="social-media pt-3 d-flex justify-content-start align-items-baseline">
          <div className="share-page align-items-baseline pr-3">
            <span>DELA:</span>
          </div>
          <div className="flex-grow pb-4">
            <Share title={post.title} url={url} />
          </div>
        </div>

      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        tag
        baseUrl
      }
    },
    allWpPost(filter: { slug: { eq: $slug } }) {
        nodes {
          title
          content
          date( formatString: "YYYY-MM-DD" ),
          slug
          author {
            node {
              lastName
              firstName
              email
              name
              nickname
              description
            }
          }
          featuredImage {
            node {
              localFile{
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }  
              },
              description
              altText
            }
          }
          seo {
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            opengraphImage {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }  
              }
            }
           
            title
            twitterDescription
            twitterTitle
            twitterImage {
              localFile{
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }  
              }
            }
            
            
          }
        }
    }
  }
`